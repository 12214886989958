import { PERMISSIONS_URL, PERMISSIONS_API_TOKEN, authToken } from '../env'
import axios from '../_client'
import { EventBus } from '@/event-bus'
const usersAxiosInstance = axios.create({
  baseURL: `${PERMISSIONS_URL}/api/v1`,
  headers: {
    'api-token': PERMISSIONS_API_TOKEN
  }
})
usersAxiosInstance.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})
export const UsersAPI = {
  getUserRoles(userId) {
    return usersAxiosInstance({
      method: 'GET',
      url: `users/${userId}/roles`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  addUserRoles(userId, data) {
    return usersAxiosInstance({
      method: 'POST',
      url: `users/${userId}/roles`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  removeUserRoles(userId, data) {
    return usersAxiosInstance({
      method: 'delete',
      url: `users/${userId}/roles`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getUserRoleWithApps(userId, withApps = true) {
    return usersAxiosInstance({
      method: 'GET',
      url: `users/${userId}/roles?v2=true&withApps=${withApps}&permissions=true`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getUsersWithSpecificPermissions(data) {
    return usersAxiosInstance({
      method: 'POST',
      url: `users/search/permissions`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  }
}
