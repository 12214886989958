import { PERMISSIONS_URL, PERMISSIONS_API_TOKEN, authToken } from '../env'
import axios from '../_client'
import { EventBus } from '@/event-bus'

const rolesAxiosInstance = axios.create({
  baseURL: `${PERMISSIONS_URL}/api/v1`,
  headers: {
    'api-token': PERMISSIONS_API_TOKEN
  }
})
rolesAxiosInstance.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})
export const RolesAPI = {
  create(data) {
    return rolesAxiosInstance({
      method: 'post',
      url: `/roles`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  updateRoleById(roleId, data) {
    return rolesAxiosInstance({
      method: 'patch',
      url: `/roles/${roleId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  delete(roleId) {
    return rolesAxiosInstance({
      method: 'delete',
      url: `/roles/${roleId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getPermissionsForARole(roleId, inactive = true) {
    return rolesAxiosInstance({
      method: 'get',
      url: `/roles/${roleId}/permissions?hierarchy=true&inactive=${inactive}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAllPermissionsForRole(roleId) {
    return rolesAxiosInstance({
      method: 'get',
      url: `/roles/${roleId}/permissions?hierarchy=true&inactive=true`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  addPermissionToRole(roleId, data) {
    return rolesAxiosInstance({
      method: 'POST',
      url: `/roles/${roleId}/permissions`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  addManyPermissionToRole(roleId, data) {
    return rolesAxiosInstance({
      method: 'POST',
      url: `/roles/${roleId}/permissions/many`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  removePermissionsFromARole(roleId, data) {
    return rolesAxiosInstance({
      method: 'DELETE',
      url: `/roles/${roleId}/permissions`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getAllRoles() {
    return rolesAxiosInstance({
      method: 'GET',
      url: `/roles?withApps=true`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  filterUsersByRole(data) {
    return rolesAxiosInstance({
      method: 'POST',
      url: `/roles/users`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  addCustomPermissionToRoleResource(roleId, data) {
    return rolesAxiosInstance({
      method: 'POST',
      url: `/roles/${roleId}/permissions/custom`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  }
}
